<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <b-card>
            <b-row>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">Employee</th>
                        </tr>
                    </thead>
                </table>
            </b-row>

            <validation-observer ref="employeeRules">
                <b-form>
                    <b-row class="mt-2 border-bottom pb-1">
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="some-radios" class="border-bottom">Employee Type* </label>
                                <validation-provider name="Employee Type" #default="{ errors }" rules="required">
                                    <b-form-radio-group>
                                        <b-form-radio v-model="employeeData.role" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="Foreman">
                                            Foreman
                                        </b-form-radio>
                                        <b-form-radio v-model="employeeData.role" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="Staff">
                                            Staff
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="some-radios" class="border-bottom">Driver </label>
                                <validation-provider name="Driver Status" #default="{ errors }" rules="required">
                                    <b-form-radio-group>
                                        <b-form-radio v-model="employeeData.is_driver" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " :value="true">
                                            Yes
                                        </b-form-radio>
                                        <b-form-radio v-model="employeeData.is_driver" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " :value="false">
                                            No
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group label="Status" label-for="status">
                                <validation-provider name="status" #default="{ errors }" rules="required">
                                    <v-select v-model="employeeData.status" :options="statusOptions" :reduce="(val) => val.value" :clearable="false" input-id="status" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <!-- <b-col cols="12" sm="6" md="4" xl="3">
              <b-form-group label="Availability" label-for="availability">
                <validation-provider name="availability" #default="{ errors }" rules="required">
                  <v-select v-model="employeeData.availability" :options="availabilityOptions"
                    :reduce="(val) => val.value" :clearable="false" input-id="availability" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
                    </b-row>
                    <b-row class="mt-2 border-bottom pb-1">
                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="firstName">Name*</label>
                                <validation-provider name="Name" #default="{ errors }" rules="required">
                                    <b-form-input id="firstName" v-model="employeeData.name" :state="errors.length > 0 ? false : null" type="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="lastName">Last Name*</label>
                                <validation-provider name="Last Name" #default="{ errors }" rules="required">
                                    <b-form-input id="lastName" v-model="employeeData.surname" :state="errors.length > 0 ? false : null" type="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="contactPerson">Email</label>
                                <validation-provider name="Email" #default="{ errors }" rules="email">
                                    <b-form-input id="contactPerson" v-model="employeeData.email" :state="errors.length > 0 ? false : null" type="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3" v-if="employeeData.id == null">
                            <b-form-group label="Password" label-for="password">
                                <validation-provider name="Password" #default="{ errors }" rules="required">
                                    <b-form-input id="password" type="text" v-model="employeeData.password" :state="errors.length > 0 ? false : null"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3" v-else>
                            <b-form-group label="Password" label-for="password">
                                <b-form-input id="password" type="text" v-model="employeeData.password"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="contactInfo">Phone</label>
                                <!-- <b-form-input id="contactInfo" v-model="employeeData.phone" type="text" /> -->
                                <cleave id="contactInfo" v-model="employeeData.phone" class="form-control" :raw="false" :options="$func.getInputOptions().phone" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="dateOfBirth">Date of Birth</label>
                                <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="employeeData.dob" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="driverLicenseNumber">Driver's License Number </label>
                                <validation-provider name="Driver License Number" #default="{ errors }" :rules="employeeData.is_driver ? 'required' : ''">
                                    <b-form-input id="driverLicenseNumber" v-model="employeeData.driving_license" :state="errors.length > 0 ? false : null" type="text" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="forkliftLicenseNumber">Forklift License Number</label>
                                <b-form-input id="forkliftLicenseNumber" v-model="employeeData.forklift_license" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="cscs">CSCS</label>
                                <b-form-input id="cscs" v-model="employeeData.cscs" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="passport_no">Passport Number</label>
                                <b-form-input id="passport_no" v-model="employeeData.passport_no" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="dateOfBirth">Passport Expiry</label>
                                <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="employeeData.passport_expiry" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="gst">NI Number</label>
                                <b-form-input id="gst" v-model="employeeData.ni_no" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="dateOfBirth">IPAF Expiry</label>
                                <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y', locale: { firstDayOfWeek: 1 } }" placeholder="DD/MM/YYYY" v-model="employeeData.ipaf_expiry" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2 border-bottom pb-1">
                        <b-col cols="12" sm="6" md="4" xl="6">
                            <b-form-group>
                                <label for="address">Street Address</label>
                                <b-form-input id="address" v-model="employeeData.address1" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="city">City</label>

                                <b-form-input id="city" v-model="employeeData.city" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="3">
                            <b-form-group>
                                <label for="post_code">Post Code</label>

                                <b-form-input id="post_code" v-model="employeeData.post_code" type="text" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="4" xl="6">
                            <b-form-group>
                                <label for="address2">Address 2</label>
                                <b-form-input id="address2" v-model="employeeData.address2" type="text" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2 border-bottom pb-1">
                        <!-- <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Day Rate UK" label-for="Day Rate UK">
                                <cleave id="day_rate" v-model="employeeData.day_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Weekend Rate UK" label-for="Weekend Rate UK">
                                <cleave id="weekend_rate" v-model="employeeData.weekend_rate" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Day Rate EU" label-for="Day Rate EU">
                                <cleave id="day_rate" v-model="employeeData.day_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Weekend Rate EU" label-for="Weekend Rate EU">
                                <cleave id="weekend_rate" v-model="employeeData.weekend_rate_eu" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Day Rate  Global" label-for="Day Rate Global">
                                <cleave id="day_rate" v-model="employeeData.day_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Weekend Rate  Global" label-for="Weekend Rate Global">
                                <cleave id="weekend_rate" v-model="employeeData.weekend_rate_glo" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Overtime (per hour)" label-for="Overtime">
                                <cleave id="overtime" v-model="employeeData.overtime" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col> -->

                        <b-col cols="12" sm="6" md="6" xl="3">
                            <b-form-group label="Lodging Allowance" label-for="Lodging Allowance">
                                <cleave id="day_rate" v-model="employeeData.lodging" class="form-control" :raw="true" :options="$func.getInputOptions().price" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col cols="12">
                            <b-button v-if="employeeData.id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save
                            </b-button>
                            <b-button v-else type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                                Save Changes
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
import { BCol, BRow, BTab, BTabs, BCard, BAlert, BLink, VBModal } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import store from "@/store";
import employeeStoreModule from "../employeeStoreModule";

import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import router from "@/router";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,

        vSelect,
        Cleave,
        ToastificationContent,

        ValidationProvider,
        ValidationObserver,
        flatPickr,
    },

    directives: {
        Ripple,
        "b-modal": VBModal,
    },

    setup() {
        const EMPLOYEE_APP_STORE_MODULE_NAME = "employee";

        // Register module
        if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME);
        });
    },

    data: function () {
        var employeeData = {
            id: null,
            name: null,
            surname: null,
            email: null,
            role: null,
            phone: null,
            address1: null,
            address2: null,
            city: null,
            post_code: null,
            dob: null,
            ni_no: null,
            passport_no: null,
            address_time: null,
            ipaf_expiry: null,
            passport_expiry: null,
            driving_license: null,
            day_rate: null,
            weekend_rate: null,
            day_rate_eu: null,
            weekend_rate_eu: null,
            lodging: null,
            day_rate_glo: null,
            weekend_rate_glo: null,
            forklift_license: null,
            overtime: null,
            cscs: null,
            is_driver: false,
            password: null,
            status: "active",
        };

        var availabilityData = {
            startDate: null,
            endDate: null,
            availability: null,
        };

        const statusOptions = [
            { label: "Active", value: "active" },
            { label: "Inactive", value: "passive" },
            { label: "Deleted", value: "deleted" },
        ];

        const availabilityOptions = [
            { label: "Available", value: "A" },
            { label: "Holiday", value: "H" },
            { label: "Sick", value: "S" },
            { label: "AWOL", value: "W" },
        ];
        return {
            employeeData: employeeData,
            availabilityData: availabilityData,
            baseURL: store.state.app.baseURL,
            required,
            alphaNum,
            email,

            roleOptions: [],
            statusOptions,
            availabilityOptions,
            employeeId: null,
            formShow: false,
            permissions: [],

            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            fetchAvailability: [],

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            config: {
                dateFormat: "d-m-Y",
                mode: "multiple",
            },

            tableColumns: [
                { key: "availability", sortable: true, class: "text-center" },
                { key: "date", sortable: true, class: "text-center" },
            ],
        };
    },

    created() {
        this.getEmployeeById();
    },

    methods: {
        availabilityAdd() {
            var tempData = {
                date: null,
                availability: null,
                userId: null,
            };

            tempData.availability = this.availability;
            tempData.userId = router.currentRoute.params.id;
            let dateSplit = this.rangeDate.split(/[ ,]+/);
            let dateArray = [];
            dateSplit.forEach((element) => {
                dateArray.push(element);
            });

            tempData.date = dateArray;

            tempData.date.forEach((element) => {
                this.fetchAvailability.push({
                    date: element,
                    availability: tempData.availability,
                    userId: tempData.userId,
                });
            });

            store.dispatch("employee/employeeAvailability", tempData).then((response) => {
                if (response.status == 200) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Successful",
                            text: "✔️ Employee Update Successful",
                            icon: "ThumbsUpIcon",
                            variant: "success",
                        },
                    });
                }
            });

            this.availability = "A";
            this.clearDate();
        },

        clearDate() {
            if (this.rangeDate != null && this.rangeDate != "") {
                this.startDate = null;
                this.endDate = null;
                this.rangeDate = null;
            }
        },

        getEmployeeById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.employeeId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("employee/fetchEmployee", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.employeeData = response.data;
                            this.fetchAvailability = response.data.availabilityList;
                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        formSubmitted() {
            this.formShow = true;

            this.$refs.employeeRules.validate().then((success) => {
                if (success) {
                    if (router.currentRoute.params.id) {
                        store
                            .dispatch("employee/updateEmployee", this.employeeData)
                            .then((response) => {
                                if (response.status == 200) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Employee Update Successful",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    setTimeout(function () {
                                        this.formShow = false;
                                        router.push({ name: "employee-view", params: { id: response.data.id } });
                                    }, 500);

                                    //  this.getEmployeeById();
                                }
                            })
                            .catch((e) => {
                                if (e.response.status == 406) {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "E-mail address must be unique.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                } else {
                                    let error = e.status.code;
                                    let message = "Process Failed with Error Code:";
                                    let error_message = message.concat(" ", error);

                                    this.formShow = false;
                                    this.$swal({
                                        title: "Error",
                                        text: error_message,
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                }
                            });
                    } else {
                        store
                            .dispatch("employee/addEmployee", this.employeeData)
                            .then((response) => {
                                if (response.status == 200) {
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Successful",
                                            text: "✔️ Employee Add Successful",
                                            icon: "ThumbsUpIcon",
                                            variant: "success",
                                        },
                                    });

                                    setTimeout(function () {
                                        this.formShow = false;
                                        // router.push({ name: 'employee-list' });
                                        router.push({ name: "employee-view", params: { id: response.data.id } });
                                    }, 500);
                                }
                            })
                            .catch((e) => {
                                if (e.response.status == 406) {
                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: "E-mail address must be unique.",
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                } else {
                                    let error = e.status.code;
                                    let message = "Process Failed with Error Code:";
                                    let error_message = message.concat(" ", error);

                                    this.formShow = false;

                                    this.$swal({
                                        title: "Error",
                                        text: error_message,
                                        icon: "error",
                                        showCancelButton: false,
                                        confirmButtonText: "OK",
                                    });
                                }

                                this.formShow = false;
                            });
                    }
                } else {
                    this.formShow = false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
